/* eslint-disable jsx-a11y/img-redundant-alt */
import UploadIcon from "@atlaskit/icon/glyph/upload";
import Panel from "@atlaskit/panel";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import convertSize from "convert-size";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { defaultStyles, FileIcon } from "react-file-icon";
import formatDate from "../../helpers/format-date-fr";
import formatSize from "../../helpers/format-size";
import ConfigurationService from "../../services/ConfigurationService";
import IssueService from "../../services/IssueService";
import "./Attachement.css";
import { TramRounded } from "@mui/icons-material";
import { Button } from "@material-ui/core";
import SimpleSnackbar from "../notifications/SimpleSnackbar";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  marginLeft: "10px",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  height: 145,
  padding: 4,
  boxSizing: "border-box",
  justifyContent: "center",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const element = {
  display: "grid",
  marginRight: "10px",
  marginTop: "15px",
};

const time = {
  color: "#707070",
  fontSize: "12px",
};

const panelHeader = {
  fontSize: 16,
  color: "#0052CC",
  textAlign: "left",
  fontWeight: "600",
};

const Header = <span style={panelHeader}>Pièces jointes</span>;

const Attachement = ({ attachment, issueKey }) => {
  const [files, setFiles] = useState(attachment);
  const [openNotif, setopenNotif] = useState(false);
  const [opneAlert, setopneAlert] = useState(false);
  const [maxfileSize, setmaxfileSize] = useState(null);
  const [maxfileSizeString, setmaxfileSizeString] = useState(null);
  const [allowedExtensionlist, setallowedExtensionlist] = useState(null);
  const [openNotification, setopenNotification] = useState(false);
  const [extensions, setextensions] = useState();

  useEffect(() => {
    getconfig();
    getAllowedExtensions();
  }, []);

  const getconfig = async () => {
    await ConfigurationService.getConfig()
      .then((res) => {
        var filesize = res.data.maxfilesize;
        filesize = filesize.slice(0, -2) + " " + filesize.slice(-2);

        setmaxfileSizeString(filesize);
        setmaxfileSize(convertSize(filesize));
      })
      .catch((erro) => {});
  };

  const getAllowedExtensions = async () => {
    await ConfigurationService.getAllowedExtensions()
      .then((res) => {
        var data = res.data;
        setextensions(data);
        let modifiedList = data.map((element) => "." + element);
        var list = modifiedList.join(",");
        setallowedExtensionlist(list);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    acceptedFiles.forEach((file) => saveAttachmentfunction(file));
    rejectedFiles.forEach((file) => setopenNotification(true));
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const handleCloseNotif = () => {
    setopenNotif(false);
  };

  const saveAttachmentfunction = async (file) => {
    if (file.size <= maxfileSize) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (!extensions.includes(extension)) {
        setopenNotification(true);
      } else {
        let formData = new FormData();
        formData.append("file", file);
        await IssueService.saveAttachment(issueKey, formData)
          .then((response) => {
            let newFiles = files.concat(file);
            setFiles(newFiles);
            setopenNotif(true);
          })
          .catch((error) => {
            setopneAlert(true);
          });
      }
    } else {
      setopneAlert(true);
    }
  };

  const thumbs = files.map((file, i) => {
    let fileName = null;
    if (file.filename) {
      fileName = file.filename;
    } else {
      fileName = file.name;
    }
    var fileExtension = fileName.substring(fileName.indexOf(".") + 1);

    let imgicon = null;

    if (file.mimeType === "image/jpeg" || file.mimeType === "image/png") {
      imgicon = process.env.REACT_APP_API_URL + "/search/attachment/" + file.id;
    }

    return (
      <div key={i} style={element}>
        <div style={thumb} key={file.filename}>
          <div style={thumbInner}>
            <FileIcon
              extension={fileExtension}
              {...defaultStyles[fileExtension]}
            />
          </div>
        </div>

        <div className="divInfoFileName">
          <span className="blender"></span>

          <Link
            href={
              process.env.REACT_APP_API_URL + "/search/attachment/" + file.id
            }
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
            className="infoFileName"
          >
            {file.filename}
          </Link>
        </div>
        <div className="infoFile">
          <div className="time" style={{ color: "grey", float: "left" }}>
            {formatDate(file.created, true)}
          </div>
          <div align="left" style={{ color: "grey", float: "right" }}>
            {formatSize(file.size)}
          </div>
        </div>
      </div>
    );
  });

  const FileAlert = () => {
    return (
      <div style={{ marginBottom: "1%" }}>
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert
            severity="warning"
            onClose={() => {
              setopneAlert(false);
            }}
          >
            la taille maximale du fichier est de {maxfileSizeString}
          </Alert>
        </Stack>
      </div>
    );
  };

  const FileAlertExtension = () => {
    return (
      <div style={{ marginBottom: "1%" }}>
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert
            severity="warning"
            onClose={() => {
              setopenNotification(false);
            }}
          >
            his File with this extension not allowed
          </Alert>
        </Stack>
      </div>
    );
  };

  return (
    <div>
      <Panel header={Header} isDefaultExpanded={true}>
        <div
          style={{ border: "1px dashed #D9D9D9", width: "85%", padding: "1%" }}
        >
          <section className="container">
            {opneAlert && <FileAlert />}
            {openNotification && <FileAlertExtension />}
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} accept={allowedExtensionlist} />
              <span style={{ color: "black" }}>
                <UploadIcon primaryColor={"grey"} size="large" />
                Glissez-déposez des fichiers pour les joindre, ou
                <Link underline="hover"> parcourir.</Link>
              </span>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
          </section>
        </div>
      </Panel>
    </div>
  );
};

export default Attachement;
