import { Field } from "@atlaskit/form";
import UploadIcon from "@atlaskit/icon/glyph/upload";
import Link from "@mui/material/Link";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { defaultStyles, FileIcon } from "react-file-icon";
import { FormContext } from "../../pages/FormContext";

import "../../pages/Issue/create/createIssue.css";

import formatDate from "../../helpers/format-date-fr";
import formatSize from "../../helpers/format-size";

import compactString from "../../helpers/compact-string";

import Button from "@atlaskit/button/standard-button";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import ProgressBar from "@atlaskit/progress-bar";
import ConfigurationService from "../../services/ConfigurationService";
import "./AttacheFile.css";
import SimpleSnackbar from "../notifications/SimpleSnackbar";

const AttacheFile = ({ field_id, field_label, required, description }) => {
  const [allowedExtensionlist, setallowedExtensionlist] = useState(null);
  const [extensions, setextensions] = useState(null);
  const [openNotification, setopenNotification] = useState(false);

  useEffect(() => {
    getAllowedExtensions();
  }, []);

  const getAllowedExtensions = async () => {
    await ConfigurationService.getAllowedExtensions()
      .then((res) => {
        var data = res.data;
        setextensions(data);
        let modifiedList = data.map((element) => "." + element);
        var list = modifiedList.join(",");
        setallowedExtensionlist(list);
      })
      .catch((erro) => {
        console.log(erro);
      });
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "text/html": [".html", ".htm"],
    },
  });
  const { handleChange } = useContext(FormContext);
  const [selectedfiles, setselectedfiles] = useState([]);

  const [flags, setFlags] = useState([]);

  const addFlag = () => {
    const newFlagId = flags.length + 1;
    const newFlags = flags.slice();
    newFlags.splice(0, 0, newFlagId);

    setFlags(newFlags);
  };

  const handleDismiss = () => {
    setFlags(flags.slice(1));
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
    fontSize: "9.5px",
    color: "grey",
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    marginBottom: 8,
    marginRight: 8,
    width: 600,
    height: 50,
    padding: 4,
    boxSizing: "border-box",
  };

  const element = {
    display: "grid",
    marginRight: "10px",
    marginTop: "15px",
  };

  const DeleteFile = (event, fileIndex) => {
    let files = selectedfiles.filter((file, index) => index !== fileIndex);
    setselectedfiles(files);
    handleChange(field_id, event, files);
  };

  const showFiles = selectedfiles.map((file, index) => {
    var fileName = file.path;
    var fileExtension = fileName.substring(fileName.indexOf(".") + 1);
    return (
      <div key={index} style={element}>
        <div style={thumb} key={index}>
          <div style={thumbInner}>
            <FileIcon
              extension={fileExtension}
              {...defaultStyles[fileExtension]}
            />
            <div className="fileNameTitleCreate">
              <div>
                {compactString(file.name, 50)}
                <div className="closeButtonTextAreaCreate">
                  <div
                    style={{
                      color: "grey",
                      float: "left",
                      paddingRight: "5px",
                    }}
                  >
                    {formatSize(file.size)}
                  </div>
                  <Button
                    id={"deleteButton_" + file.name}
                    spacing="compact"
                    appearance="subtle"
                    className="buttonCloseCreate"
                    onClick={(e) => {
                      DeleteFile(e, index);
                    }}
                  >
                    <CrossIcon />
                  </Button>
                </div>
              </div>

              <div style={{ paddingTop: "10px", width: "470px" }}>
                <ProgressBar value={1} ariaLabel="Progress bar label" />
              </div>
            </div>
          </div>
        </div>
        <div className="infoFile">
          <div className="time" style={{ color: "grey", float: "left" }}>
            {formatDate(file.lastModifiedDate, true)}
          </div>
        </div>
      </div>
    );
  });

  const handelCloseNotif = () => {
    setopenNotification(false);
  };

  return (
    <Field label={field_label} name={field_id} defaultValue="">
      {() => (
        <Fragment>
          <Dropzone
            onDrop={(files, event) => {
              var filterFiles = files.filter((file) => {
                // Get the file extension
                const extension = file.name.split(".").pop().toLowerCase();

                // Check if the extension is not in the list of extensions to delete
                if (!extensions.includes(extension)) {
                  setopenNotification(true);
                  return false; // Filter out files with disallowed extensions
                }
                return true;
              });
              setselectedfiles((prev) => [...prev, ...filterFiles]);
              handleChange(field_id, event, filterFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="containerFile">
                <div
                  {...getRootProps({
                    className: "dropzone",
                  })}
                >
                  <input
                    id="InputFileCreateIssue"
                    {...getInputProps()}
                    accept={allowedExtensionlist}
                  />

                  <span style={{ color: "black", fontSize: "14px" }}>
                    <div style={{ float: "left" }}>
                      <UploadIcon primaryColor={"grey"} Size="large" />
                    </div>
                    Glissez-déposez des fichiers pour les joindre, ou
                    <Link underline="hover"> parcourir</Link>.
                  </span>
                </div>
                <br />
                <aside>{showFiles}</aside>
                <SimpleSnackbar
                  open={openNotification}
                  handleClose={handelCloseNotif}
                />
              </div>
            )}
          </Dropzone>
        </Fragment>
      )}
    </Field>
  );
};
export default AttacheFile;
