import Panel from "@atlaskit/panel";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import "./StateProgress.css";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 16,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#68F461",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#68F461",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 8,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#F4C542",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#68F461",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className=""
    ></ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const stepsDscComplement = [
  "Initié",
  "En attente expertise",
  "Préparation ordre de paiement",
  "Complément Dossier",
  "En attente de validation CR",
  "Préparation du chèque",
  "Chèque Remis au client",
  "Clos",
];

const stepsDsc = [
  "Initié",
  "En attente expertise",
  "Préparation ordre de paiement",
  "En attente de validation CR",
  "Préparation du chèque",
  "Chèque Remis au client",
  "Clos",
];

const stepDscDg = [
  "Initié",
  "En attente expertise",
  "En attente de validation CR",
  "En attente de validation DG",
  "Préparation ordre paiement",
  "En attente de validation CR",
  "Préparation du chèque",
  "Chèque Remis au client",
  "Clos",
];
const stepsBDG = ["ODR Evoyé", "ODR Validé", "Missionné", "Clos"];

const stepDsp = [
  "initié",
  "Traitement CDC",
  "Accord DR",
  "Attente devis",
  "Expertise",
  "Validation",
  "Réparation",
  "Fin réparation",
  "Clos",
];

const panelHeader = {
  fontSize: 16,
  color: "#0052CC",
  textAlign: "left",
  fontWeight: "600",
};

const StepProgressNew = ({
  issueType,
  status,
  activeStep,
  flagDG,
  project,
}) => {
  const arrayStep = () => {
    if (issueType === "Sinistre" && project === "DSC") {
      if (status === "Complément dossier") {
        return stepsDscComplement;
      } else {
        if (flagDG === "Oui") {
          return stepDscDg;
        } else {
          return stepsDsc;
        }
      }
    } else {
      if (issueType === "Sinistre" && project === "DSF") {
        return stepDsp;
      } else {
        return stepsBDG;
      }
    }
  };

  const Header = <span style={panelHeader}>Avancement des étapes</span>;

  const activateStepId = () => {
    if (activeStep) {
      const splitArray = activeStep
        .split("|")
        .filter((string) => string !== "");
      if (
        splitArray.length === stepsDsc.length ||
        splitArray.length === stepsDscComplement.length ||
        splitArray.length === stepsBDG.length ||
        splitArray.length === stepDscDg.length ||
        splitArray.length === stepDsp.length
      ) {
        return splitArray.length;
      } else {
        return splitArray.length - 1;
      }
    } else {
      return 0;
    }
  };
  return (
    <div>
      <Panel header={Header} isDefaultExpanded={true}>
        <Stack sx={{ width: "50%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={activateStepId()}
            connector={<ColorlibConnector />}
          >
            {arrayStep().map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Panel>
    </div>
  );
};

export default StepProgressNew;
